import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import TextAreaField from "app/components/ui/TextAreaField";
import { ConnectPaymentMethod } from "app/models/paymentMethod";
import usePaymentAccount from "app/hooks/usePaymentAccount";

import { getSchema } from "../utils";
import EnableTwoStepAuthModal from "../../../components/EnableTwoStepAuthModal";
import { GenericResponse } from "app/api/types";

interface Props {
  accountType: ConnectPaymentMethod;
  onSuccess: (d: GenericResponse<{ token: string; message: string }>) => void;
}

export default function AddUSDAccount(props: Props) {
  const { accountType, onSuccess } = props;

  const { isInitializing, initialize } = usePaymentAccount();

  const schema = getSchema({
    accountType,
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isPersonalAccount = accountType.type === "personal";

  const onSubmit = handleSubmit((data) => {
    const fields = Object.entries(data)
      .filter(([_, value]) => value !== "")
      .map(([key, value]) => ({ name: key, value }));

    initialize(
      {
        payment_method: accountType.id,
        fields,
      },
      {
        onSuccess: (data) => {
          onSuccess(data.data);
        },
      }
    );
  });

  return (
    <form onSubmit={onSubmit} tw="mt-[24px]">
      <Card tw="mt-[10px] p-[20px]">
        <Text medium semiBold>
          Account details
        </Text>

        <AccountDetails>
          <TextField
            label="Account number or IBAN"
            placeholder="Enter your account number or IBAN"
            type="text"
            {...register("account_number")}
            error={!!errors.account_number?.message}
            errorMessage={errors.account_number?.message as string}
          />

          <TextField
            label="Routing number or Swift code"
            placeholder="Enter Swift code or Routing number"
            type="text"
            {...register("swift_code")}
            error={!!errors.swift_code?.message}
            errorMessage={errors.swift_code?.message as string}
          />

          <TextField
            label={`${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            type="text"
            {...register(
              isPersonalAccount
                ? "recipient_account_name"
                : "company_account_name"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_account_name?.message
                : !!errors.company_account_name?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_account_name?.message
                : errors.company_account_name?.message) as string
            }
          />

          <TextField
            label="Bank name"
            placeholder="Enter bank name"
            type="text"
            {...register("bank_name")}
            error={!!errors.rootbank_name?.message}
            errorMessage={errors.bank_name?.message as string}
          />

          <TextAreaField
            rows={3}
            label="Bank address"
            placeholder="Enter bank address"
            {...register("bank_address")}
            error={!!errors.bank_address?.message}
            errorMessage={errors.bank_address?.message as string}
          />

          <TextAreaField
            rows={3}
            label={`${isPersonalAccount ? "Recipient" : "Company"} address`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } address`}
            {...register(
              isPersonalAccount ? "recipient_address" : "company_address"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_address?.message
                : !!errors.company_address?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_address?.message
                : errors.company_address?.message) as string
            }
          />
        </AccountDetails>

        <Button primary fullWidth type="submit" loading={isInitializing}>
          Save
        </Button>
      </Card>
      <EnableTwoStepAuthModal />
    </form>
  );
}

const AccountDetails = styled.div`
  ${tw`flex flex-col gap-[16px] mt-[16px] mb-[24px] `}
`;
