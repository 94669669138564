import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import { ReactComponent as Success } from "app/assets/icons/success.svg";
import { ReactComponent as Error } from "app/assets/icons/error_plug.svg";

type Action = {
  label: string;
  url?: string;
  onAction?: VoidFunction;
};

interface Props {
  status: "success" | "error";
  title?: string;
  description: string | JSX.Element;
  primaryAction?: Action;
  className?: string;
}

function StatusView(props: React.PropsWithChildren<Props>) {
  const { status, description, primaryAction, className, title } = props;

  const Navigate = useNavigate();

  return (
    <Container className={className}>
      <div className="main">
        <div className="main-details">
          {status === "success" && <Success />}
          {status === "error" && <Error className="status-icon" />}
          <div className="text-content">
            {title && (
              <Text bold className="title">
                {title}
              </Text>
            )}
            <Text className="description">{description}</Text>
          </div>
        </div>

        {props.children}
      </div>

      {primaryAction ? (
        primaryAction.url ? (
          <Button primary onClick={() => Navigate(`${primaryAction.url}`)}>
            {primaryAction.label}
          </Button>
        ) : (
          <Button primary onClick={primaryAction.onAction}>
            {primaryAction.label}
          </Button>
        )
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  padding: 50px 20px 20px 20px;
  ${tw`flex flex-col gap-[24px] items-center`};

  .main {
    ${tw`flex flex-col items-center gap-[10px] w-full`};

    .main-details {
      ${tw`flex flex-col items-center gap-[32px]`};

      .status-icon {
        ${tw` dark:fill-current text-white w-[96px] h-[96px]`}
      }

      .text-content {
        ${tw`flex flex-col items-center gap-[8px]`};
        text-align: center;

        .title {
          font-size: 2rem;
        }
        .description {
          ${tw`text-[1.6rem] sm:text-[1.8rem] text-center w-[375px] max-w-full`};
        }
      }
    }
  }

  > button {
    padding: 14px 28px;
  }
`;

export default StatusView;
