import {
  BusinessVerificationSection,
  KYBStep,
} from "app/models/businessVerification";

const SECTION_KEY_TO_PATH_MAP: Record<string, string> = {
  business_profile: "profile",
  business_address: "address",
  business_registration: "registration",
  business_ownership: "ownership",
  business_transaction_information: "transaction",
  business_documents: "documents",
};

const REVIEW_AND_FINISH_STEP: KYBStep = {
  title: "Review & Finish",
  path: "review",
  completed: false,
  section_key: "review",
  status: "pending",
};

export const transformSectionsToSteps = (
  sections: BusinessVerificationSection[]
): KYBStep[] => {
  const transformedSteps = sections.map((section) => ({
    title: section.display_name,
    path: SECTION_KEY_TO_PATH_MAP[section.section_key] || section.section_key, // Use mapped path, fallback to section_key if missing
    completed: section.status === "submitted",
    section_key: section.section_key,
    status: section.status,
  }));

  return [...transformedSteps, REVIEW_AND_FINISH_STEP];
};

export enum BusinessDocumentCategories {
  // Business address documents
  BUSINESS_PROOF_OF_ADDRESS = "business_proof_of_address",

  // Business corporate documents
  MEMART = "memart",
  AML_POLICY = "aml_policy",
  PROOF_OF_FUNDS = "proof_of_funds",
  PROOF_OF_WEALTH = "proof_of_wealth",
  BOARD_RESOLUTION = "board_resolution",
  REGULATORY_LICENSES = "regulatory_licenses",
  CORPORATE_STRUCTURE_CHART = "corporate_structure_chart",
  CORPORATE_REGISTRY_EXTRACT = "corporate_registry_extract",
  CERTIFICATE_OF_INCORPORATION = "certificate_of_incorporation",

  // Business owner documents
  BUSINESS_OWNER_PROOF_OF_ID = "business_owner_proof_of_id",
  BUSINESS_OWNER_PROOF_OF_ADDRESS = "business_owner_proof_of_address",
}
