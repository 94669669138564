import { useEffect } from "react";
import tw, { styled } from "twin.macro";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import TextAreaField from "app/components/ui/TextAreaField";
import { ConnectPaymentAccount as PaymentAccount } from "app/models/paymentAccount";
import { getSchema } from "app/views/BankAccountsAdd/utils";
import useUpdatePaymentAccount from "../hook/useUpdatePaymentAccount";

interface Props {
  account: PaymentAccount;
  onContinue: VoidFunction;
}

export default function EditUSDAccount(props: Props) {
  const { account, onContinue } = props;

  const { updateAccount, isUpdatingPaymentAccount } = useUpdatePaymentAccount(
    account.id
  );

  const schema = getSchema({
    accountType: account.payment_method,
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const updatableStatus = account.fields.reduce((acc, field) => {
    acc[field.key] = !field.is_updatable;
    return acc;
  }, {} as Record<string, boolean>);

  useEffect(() => {
    const defaultValues = account.fields.reduce((acc, field) => {
      acc[field.key] = field.value;
      return acc;
    }, {} as Record<string, string>);
    reset(defaultValues);
  }, [reset, account]);

  const isPersonalAccount = account.payment_method.type === "personal";

  const onSubmit = handleSubmit((data) => {
    const fields = Object.entries(data)
      .filter(([_, value]) => value !== "")
      .map(([key, value]) => ({ name: key, value }));

    updateAccount(
      {
        payment_method: account.payment_method.id,
        fields,
      },
      {
        onSuccess: () => onContinue(),
      }
    );
  });

  return (
    <form onSubmit={onSubmit} tw="mt-[24px]">
      <Card tw="mt-[10px] p-[20px]">
        <Text medium semiBold>
          Account details
        </Text>

        <AccountDetails>
          <TextField
            label="Account number or IBAN"
            placeholder="Enter your account number or IBAN"
            type="text"
            disabled={updatableStatus["account_number"]}
            {...register("account_number")}
            error={!!errors.account_number?.message}
            errorMessage={errors.account_number?.message as string}
          />

          <TextField
            label="ACH Routing number"
            placeholder="Enter Swift code or Routing number"
            type="text"
            disabled={updatableStatus["swift_code"]}
            {...register("swift_code")}
            error={!!errors.swift_code?.message}
            errorMessage={errors.swift_code?.message as string}
          />

          <TextField
            label={`${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } account name`}
            type="text"
            disabled={
              updatableStatus[
                isPersonalAccount
                  ? "recipient_account_name"
                  : "company_account_name"
              ]
            }
            {...register(
              isPersonalAccount
                ? "recipient_account_name"
                : "company_account_name"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_account_name?.message
                : !!errors.company_account_name?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_account_name?.message
                : errors.company_account_name?.message) as string
            }
          />

          <TextField
            label="Bank name"
            placeholder="Enter bank name"
            type="text"
            disabled={updatableStatus["bank_name"]}
            {...register("bank_name")}
            error={!!errors.bank_name?.message}
            errorMessage={errors.bank_name?.message as string}
          />

          <TextAreaField
            rows={3}
            label="Bank address"
            placeholder="Enter bank address"
            disabled={updatableStatus["bank_address"]}
            {...register("bank_address")}
            error={!!errors.bank_address?.message}
            errorMessage={errors.bank_address?.message as string}
          />

          <TextAreaField
            rows={3}
            label={`${isPersonalAccount ? "Recipient" : "Company"} address`}
            placeholder={`Enter ${
              isPersonalAccount ? "Recipient" : "Company"
            } address`}
            disabled={
              updatableStatus[
                isPersonalAccount ? "recipient_address" : "company_address"
              ]
            }
            {...register(
              isPersonalAccount ? "recipient_address" : "company_address"
            )}
            error={
              isPersonalAccount
                ? !!errors.recipient_address?.message
                : !!errors.company_address?.message
            }
            errorMessage={
              (isPersonalAccount
                ? errors.recipient_address?.message
                : errors.company_address?.message) as string
            }
          />
        </AccountDetails>

        <Button
          primary
          fullWidth
          disabled={!isDirty}
          type="submit"
          loading={isUpdatingPaymentAccount}
        >
          Save
        </Button>
      </Card>
    </form>
  );
}

const AccountDetails = styled.div`
  ${tw`flex flex-col gap-[16px] mt-[16px] mb-[24px] `}
`;
