import * as Yup from "yup";

import { ConnectPaymentMethod, ConnectPaymentMethodField } from "app/models/paymentMethod";

type GetSchemaProps = {
  accountType: ConnectPaymentMethod;
};


const getField = (fields: Array<ConnectPaymentMethodField>, key: string) => fields?.find(f => f.name === key)


export const getSchema = ({
  accountType
}: GetSchemaProps) => {
  const isBusinessAccount = accountType.type === "business"

  const schema = Yup.object().shape({
    bank_name: Yup.string()
      .required("Bank name is a required field")
      .min(2, "Bank name is too short")
      .max(50),
    bank_address: Yup.string()
      .required("Bank address is a required field")
      .min(5, "Bank address is too short")
      .max(150),
    [isBusinessAccount ? "company_account_name" : "recipient_account_name"]:
      Yup.string()
        .required(
          `${isBusinessAccount ? "Company" : "Recipient"
          } account name is required`
        )
        .min(2, "Account name is too short")
        .max(50),
    account_number: Yup.string()
      .required("Account number is required")
      .matches(getField(accountType?.fields, 'account_number')?.validator as RegExp),
    swift_code: Yup.string()
      .required("Swift code is required")
      .matches(
        getField(accountType?.fields, 'swift_code')?.validator as RegExp,
        "Wrong format"
      ),
    [isBusinessAccount ? "company_address" : "recipient_address"]: Yup.string()
      .required(
        `${isBusinessAccount ? "Company" : "Recipient"} address is required`
      )
      .min(2, "Address is too short")
      .max(150),
  });

  return schema;
};
