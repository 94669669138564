import * as React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { useIntercom } from "react-use-intercom";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import media from "app/styles/media";
import { ReactComponent as DisabledLogo } from "app/assets/icons/busha-alert-icon.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AccountDeactivatedModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const { show: showChat } = useIntercom();

  const handleContactSupport = () => {
    showChat();
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose} showClose={false} tw="w-[400px]">
      <Container>
        <TextContent>
          <DisabledLogo />
          <div className="texts">
            <Text tw="text-[20px]" semiBold>
              Account deactivated!
            </Text>
            <Text subdued>
              Oops! Looks like your account has been deactivated and you have
              been logged out from this device. Please contact support to
              resolve this issue.
            </Text>
          </div>
        </TextContent>

        <Divider />
        <ButtonContent>
          <div className="actions">
            <Button
              fullWidth
              tw="bg-[#12A232] text-[#F2F2F2]"
              onClick={handleContactSupport}
            >
              Contact Support
            </Button>
            <Button fullWidth tw="bg-[#E5E5E5] text-black" onClick={onClose}>
              Dismiss
            </Button>
          </div>
        </ButtonContent>
      </Container>
    </Modal>
  );
};

export default AccountDeactivatedModal;

const Container = styled.div`
  ${tw`flex flex-col gap-[24px]`};
  padding: 24px;

  ${media.mobile} {
    padding: 24px 16px 40px 16px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid rgba(91, 97, 110, 0.2);
  margin: 10px 0 0 0;
`;

const TextContent = styled.div`
  ${tw`flex flex-col items-center gap-[24px]`};
  ${tw`text-center px-[28px]`};

  ${media.mobile} {
    padding: 0;
  }

  .texts {
    ${tw`flex flex-col items-center gap-[8px]`};
  }
`;

const ButtonContent = styled.div`
  ${tw`flex flex-col gap-[8px]`};

  .actions {
    ${tw`flex items-center gap-[8px] pt-[16px]`};
    ${media.mobile} {
      ${tw`flex-col`}
    }
  }
`;
